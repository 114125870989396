import React from 'react';

import Layout from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import Documentation from '../components/Documentation';

const DocumentationPage = () => (
  <Layout>
    <SectionHeader>Documentation</SectionHeader>
    <Documentation />
  </Layout>
);

export default DocumentationPage;
