import React from 'react';
import styled from 'styled-components/macro';
import ReactMarkdown from 'react-markdown/with-html';
import Card from './Card';
import media from '../modules/media';
import {pink, gray} from '../modules/colors';

const sections = [
  {
    title: 'Introduction',
    content: `This document is meant to be an overview of all of the capabilities
of GraphQLsheet and how you can best leverage those capabilities.
We will also help you understand exactly how GraphQLsheet works,
the limitations, the permissions we are requesting to access your Google account
and help you integrate the APIs you will create with your app.
If you have any questions, please [talk to us](https://graphqlsheet.com/contact).`,
    header: true
  },
  {
    title: 'Getting Started',
    content: `The API creation is super simple. We leverage Google APIs to log you in,
ask for your permission to access Google Drive and then you can create the API for
the Google Spreadsheet you want.`,
    header: true
  },
  {
    title: 'Overview',
    content: `GraphQLsheet creates a GraphQL API from your Google Spreadsheet.
Google offers a REST API that users can use but it's a lot more effort to setup yourself
and of course, it's not GraphQL. GraphQL offers many advantages over REST such as a clean, self documented API
(you can access GraphiQL for your GraphQLsheet APIs), multiple requests in one and you
can request only that data fields you want.`,
    header: false
  },
  {
    title: 'Spreadsheets',
    content: `**Important**: The spreadsheet must have alphanumeric string values (that don't start with a number)
on the first row.
The second row and the rest must have actual values (strings, number, booleans) to define the GraphQL schema.

A typical spreadsheet has columns with values with the first row having the column names.
That's the assumption that GraphQLsheet is based on. We use the first row data as keys
to create the GraphQLsheet schema and then analyze the column values to find patterns.
If a column has numeric values, we define the GraphQL schema based on that information.
We understand numbers, booleans and strings.

The spreadsheet should not have empty rows. An error will be thrown when you try to create
the API in that case.

If a spreadsheet is very big (more than 50000 values) we will not analyze it and will accept all columns as
if they contain strings. Also, because we will not analyze it, any empty rows at the end will not be removed
and will exist as if they contain empty string values. Feel free to remove them manually.

To sum up,
[This is an example
  spreadsheet](https://docs.google.com/spreadsheets/d/1U9PRElcdO64tiiPgCBxMUs74Yyu8pvmq4QZsEhFoiEI/edit#gid=0)

You can always make changes to the spreadsheet manually (through Google Drive)
and re-create the API though the dashboard.`,
    header: false
  },
  {
    title: 'Pricing',
    content: `We currently offer 2 different pricing options. In all available packages
(Free, Plus, Pro), you can create as many GraphQLsheet APIs as you want.
The main difference between the 2 packages is the number of requests that you can do.
Please bare in mind that requests through GraphiQL are counted against your total available requests limit.`,
    header: false
  },
  {
    title: 'GraphQL API',
    content: `If you ever used GraphQL, you know what it can do, and for sure you want to
use it everywhere, and that's what GraphQLsheet is here to help with. We support
Create Read Update and Delete operations, or Queries and Mutations as those are called in GraphQL.

Following is a complete example of a get request in JavaScript. You can use any language to do a simple POST
request and use the API.
Make sure to replace:
- <strong>YOUR_API_TOKEN</strong>: with your token which you can find in your dashboard after you create your API.
- <strong>YOUR_API_URL</strong>: with your API url, also found in the dashboard
- <strong>YOUR_API_FIELDS</strong>: Values from the first row of your spreadhsheet
\`\`\`js
const queryData = async () => {
  const token = YOUR_API_TOKEN;
  const apiUrl = YOUR_API_URL;
  const response = await fetch(\`\${apiUrl}?token=\${token}\`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(\`
      {
        get (limit: 10) {
          YOUR_API_FIELDS
        }
      }
    \`)
  });
  const responseJSON = await response.json();
  return responseJSON;
};

const data = queryData();
console.log(data);
\`\`\`
`,
    header: true
  },
  {
    title: 'Queries',
    content: 'Queries are used to retrieve data. We support a single but powerful query called "get".',
    header: true
  },
  {
    title: 'Get',
    content: `
It can be used to get specific rows from the spreadsheet based on the params you use.
Available params:
- limit (Int): Max # of rows to read at once (default 10)
- offset (Int): Start reading from row # (default 0)
- query (Object): Search for specific rows

And it will return the matched rows of the spreadsheet.

For example
\`\`\`js
{
  get(limit: 10, offset: 2) {
    name
    age
  }
}
\`\`\`

Query example
\`\`\`js
{
  get(query: {name: "George"}) {
    name
    age
  }
}
\`\`\`
`,
    header: false
  },
  {
    title: 'Mutations',
    content: 'Mutations is how we change data in the spreadsheet. We support "add", "update" and "remove".',
    header: true
  },
  {
    title: 'Add',
    content: `This mutation allows you to add new data in the spreadsheet. That data is added
at the end of the spreadsheet. The params of this mutation depend on the structure of
your spreadsheet and will be determined on the API creationg phase. All params are mandatory.
This mutation returns the created row.

For example
\`\`\`js
mutation {
  add(name: "George", age: 12, height: 145, member: true) {
    name
    age
  }
}

\`\`\`
`,
    header: false
  },
  {
    title: 'Update',
    content: `This mutation allows you to update specific rows in the spreadsheet. The params of
this mutation depend on the structure of your spreadsheet and will be determined on the API
creationg phase. Use the query to find the rows to update and the rest of the params
for the values you want to update. This mutation returns an array with the updated rows.

For example
\`\`\`js
mutation {
  update(query: {name: "George"}, age: 11, height: 142, member: false) {
    name
    age
  }
}
\`\`\``,
    header: false
  },
  {
    title: 'Remove',
    content: `This mutation allows you to remove data from the spreadsheet. The params of
this mutation depend on the structure of your spreadsheet and will be determined on
the API creationg phase. Use the available params to find the rows to remove.
This mutation returns an array with the deleted rows.

For example
\`\`\`js
mutation {
  remove(name: "George") {
    name
    age
  }
}
\`\`\``,
    header: false
  },
  {
    title: 'Authentication',
    content: `Each API has a unique API token that you need to send with your request to access
the data. That API token secures that requests without it wont be able to read or write data to your spreadsheet.`,
    header: true
  },
  {
    title: 'Privacy',
    content: `The privacy and safety of your data is our top priority.
We never store any data other than the first row of your spreadsheet that we use to create the GraphQL API schema.
We request the minimum permissions from your Google account. Using the option to create an example spreadsheet,
we request access only to that file and other files we will create for you
and we can't access any other file from your account.
We suggest that you use this option and then edit that example spreadsheet to paste your own data in it.
Just don't forget to recreate the API (through your dashboard) after you change the spreadsheet data.`,
    header: true
  },
  {
    title: 'Limitations',
    content: `We currently support access only to the first sheet of your spreadsheet
If you need access to a different sheet, the easiest solution right now is to
create a new Google Spreasheet and move your data there, and create a GraphQLsheet API
for it. Based on the user feedback we will consider adding the ability to access
multiple sheets in the future.`,
    header: true
  },
  {
    title: 'HTTP Status Codes',
    content: `We use stardard HTTP Status Codes to respond to your API requests

| Code               | Description         |
| ------------------ | ------------------- |
| 200                | OK                  |
| 400                | Bad Request         |
| 401                | Unauthorized        |
| 404                | Not Found           |
| 429                | Rate limit exceeded |
`,
    header: true
  },
  {
    title: 'Unauthorize GraphQLsheet',
    content: `You can always revoke the GraphQLsheet's permission to access your Google Drive files
in the [Google Account Permissions page](https://myaccount.google.com/u/0/permissions).`,
    header: true
  }
];

const SidebarWrapper = styled.div`
  width: 150px;
  height: 100%;

  ${media.tablet`display: none;`}
`;

const Header = styled.div`
  ${props =>
    props.header
      ? `
    font-weight: bold;
    padding: 14px 0;
    `
      : `
    font-weight: normal;
    padding: 4px 4px 4px 4px;
  `};

  a {
    color: ${gray};

    &:hover {
      color: ${pink};
    }
  }
`;

const Sidebar = () => (
  <SidebarWrapper>
    {sections.map(section => (
      <Header key={section.title} header={section.header}>
        <a href={`#${section.title.toLowerCase().replace(/ /g, '-')}`}>{section.title}</a>
      </Header>
    ))}
  </SidebarWrapper>
);

const MainWrapper = styled.div`
  width: calc(100% - 150px);
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;

  ${media.tablet`
    width: 100%;
    overflow-y: auto;
    padding: 0 10px;
  `};

  p {
    margin-bottom: 50px;
  }

  a {
    color: ${pink};
  }

  table {
    thead {
      text-align: left;
    }

    td {
      width: 150px;
    }
  }
`;

const MainHeader = styled.h2``;

const Main = () => (
  <MainWrapper>
    {sections.map(section => (
      <React.Fragment key={section.title}>
        <MainHeader id={section.title.toLowerCase().replace(/ /g, '-')}>{section.title}</MainHeader>
        <ReactMarkdown source={section.content} escapeHtml={false} />
      </React.Fragment>
    ))}
  </MainWrapper>
);

const Wrapper = styled.div`
  padding: 40px;

  ${media.tablet`
    padding: 10px;
  `};

  > div {
    flex-direction: row;
    height: 800px;
    margin-bottom: 40px;

    ${media.tablet`
      height: auto;
    `};
  }
`;

const Documentation = () => (
  <Wrapper>
    <Card>
      <Sidebar />
      <Main />
    </Card>
  </Wrapper>
);

export default Documentation;
